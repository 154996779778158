import React, { useState, useEffect } from 'react';
import {
    Box, Toolbar, Container, Typography, Button, Select, MenuItem, Grid, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton, Checkbox, FormControlLabel
} from '@mui/material';
import AdminHeader from '../AdminHeader';
import AdminMenuDrawer from '../AdminMenuDrawer';
import { useApi } from '../../../contexts/Apicontext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quillEditorStyles.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import AWS from 'aws-sdk';

const drawerWidth = 240;

const TAGS = [
    "Algebra",
    "Advanced Math",
    "Problem-Solving and Data Analysis",
    "Geometry and Trigonometry"
];

const DETAILED_TAGS = {
    "Algebra": [
        "Linear equations in one variable",
        "Linear functions in one variable",
        "Linear equations in two variables",
        "Systems of two linear equations in two variables",
        "Linear inequalities in one or two variables"
    ],
    "Advanced Math": [
        "Equivalent expressions",
        "Nonlinear equations in one variable and systems of equations in two variables",
        "Nonlinear functions"
    ],
    "Problem-Solving and Data Analysis": [
        "Ratios, rates, proportional relationships, and units",
        "Percentages",
        "One-variable data: Distributions and measures of center and spread",
        "Two-variable data: Models and scatterplots",
        "Probability and conditional probability",
        "Inference from sample statistics and margin of error",
        "Evaluating statistical claims: Observational studies and experiments"
    ],
    "Geometry and Trigonometry": [
        "Area and volume",
        "Lines, angles, and triangles",
        "Right triangles and trigonometry",
        "Circles"
    ]
};

const BACKEND_URLS = {
    "Algebra": {
        "Linear equations in one variable": "/generate-question/MATH/Algebra/LE1V",
        "Linear functions in one variable": "/generate-question/MATH/Algebra/LF1V",
        "Linear equations in two variables": "/generate-question/MATH/Algebra/LE2V",
        "Systems of two linear equations in two variables": "/generate-question/MATH/Algebra/S2LE2V",
        "Linear inequalities in one or two variables": "/generate-question/MATH/Algebra/LIE12V"
    },
    "Advanced Math": {
        "Equivalent expressions": "/generate-question/MATH/ADVANCEDMATH/EE",
        "Nonlinear equations in one variable and systems of equations in two variables": "/generate-question/MATH/ADVANCEDMATH/NE1V",
        "Nonlinear functions": "/generate-question/MATH/ADVANCEDMATH/NF"
    },
    "Problem-Solving and Data Analysis": {
        "Ratios, rates, proportional relationships, and units": "/generate-question/MATH/PROBLEMSOLVINGnDATA/RRPRU",
        "Percentages": "/generate-question/MATH/PROBLEMSOLVINGnDATA/PERCENTAGE",
        "One-variable data: Distributions and measures of center and spread": "/generate-question/MATH/PROBLEMSOLVINGnDATA/1VD",
        "Two-variable data: Models and scatterplots": "/generate-question/MATH/PROBLEMSOLVINGnDATA/2VD",
        "Probability and conditional probability": "/generate-question/MATH/PROBLEMSOLVINGnDATA/PnCP",
        "Inference from sample statistics and margin of error": "/generate-question/MATH/PROBLEMSOLVINGnDATA/ISSnME",
        "Evaluating statistical claims: Observational studies and experiments": "/generate-question/MATH/PROBLEMSOLVINGnDATA/ESC"
    },
    "Geometry and Trigonometry": {
        "Area and volume": "/generate-question/MATH/GEOMETRY/AnV",
        "Lines, angles, and triangles": "/generate-question/MATH/GEOMETRY/LAT",
        "Right triangles and trigonometry": "/generate-question/MATH/GEOMETRY/RTnT",
        "Circles": "/generate-question/MATH/GEOMETRY/CIRCLE"
    }
};

const difficultyMapping = {
    '하': 3,
    '중': 2,
    '상': 1,
    3: '하',
    2: '중',
    1: '상',
    'Easy': 3,
    'Medium': 2,
    'Hard': 1
};

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

const uploadFileToS3 = (file, quizID, imageIndex, optionName) => {
    const params = {
        Bucket: 'agoran-bucket',
        Key: `/AI-MATH/${quizID}/option-${optionName}-${imageIndex}-${file.name}`,
        Body: file
    };

    return s3.upload(params).promise();
};

const AIAdminPage = () => {
    const [selectedTag, setSelectedTag] = useState('');
    const [selectedDetailedTag, setSelectedDetailedTag] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [questionType, setQuestionType] = useState('multiple choice');
    const [includeImage, setIncludeImage] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editQuestion, setEditQuestion] = useState(null);
    const [numQuestions, setNumQuestions] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
    const [uploadedImageCount, setUploadedImageCount] = useState(0);
    const apiBaseUrl = useApi();

    const handleTagChange = (event) => {
        setSelectedTag(event.target.value);
        setSelectedDetailedTag('');
    };

    const handleGenerateQuestion = async () => {
        if (!selectedTag || !selectedDetailedTag || !difficulty || !questionType || numQuestions < 1) {
            alert('모든 필드를 선택해 주세요.');
            return;
        }

        const requestData = {
            difficulty: difficulty,
            questionType: questionType,
            includeImage: includeImage
        };

        const backendUrl = BACKEND_URLS[selectedTag]?.[selectedDetailedTag];
        if (!backendUrl) {
            alert('잘못된 태그 또는 세부 태그 선택입니다.');
            return;
        }

        setLoading(true);

        const token = localStorage.getItem('token');
        const fetchWithRetry = async (url, options, retries = 5) => {
            for (let i = 0; i < retries; i++) {
                const response = await fetch(url, options);
                if (response.ok) {
                    return response;
                } else if (response.status >= 500 && i < retries - 1) {
                    // Retry only for server errors (5xx)
                    continue;
                } else {
                    throw new Error(`Failed to fetch: ${response.statusText}`);
                }
            }
        };

        try {
            let generatedQuestions = [];
            for (let i = 0; i < numQuestions; i++) {
                console.log(requestData);
                const response = await fetchWithRetry(`${apiBaseUrl}${backendUrl}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestData)
                });
                const data = await response.json();

                // Check if the difficulty exists in the mapping, otherwise default to 3
                data.difficulty = difficultyMapping[data.difficulty] || 3;
                data.tag = selectedTag; // 선택한 태그 추가
                data.detailed_tag = selectedDetailedTag; // 선택한 세부 태그 추가
                data.id = Date.now() + Math.random(); // 임시 고유 ID 추가
                generatedQuestions.push(data);
            }
            setQuestions(generatedQuestions);
        } catch (error) {
            alert(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };


    const handleEditClick = (question) => {
        const mappedEditQuestion = {
            ...question,
            difficulty: difficultyMapping[question.difficulty]
        };
        setEditQuestion(mappedEditQuestion);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditQuestion(null);
    };

    const handleSaveClick = async () => {
        const reverseDifficultyMapping = {
            '하': 3,
            '중': 2,
            '상': 1
        };

        const mappedEditQuestion = {
            ...editQuestion,
            difficulty: reverseDifficultyMapping[editQuestion.difficulty],
            questiontext: editQuestion.questiontext || ''
        };

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiBaseUrl}/generate-question/MATH/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(mappedEditQuestion)
            });

            if (response.ok) {
                alert('Question information has been updated.');
                handleCloseEditDialog();
                setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== editQuestion.id)); // Remove the saved question from the list
            } else {
                alert('Failed to update question information.');
            }
        } catch (error) {
            alert('Error updating question information.');
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditQuestion({ ...editQuestion, [name]: value });
    };

    const handleContentChange = (index, field, value) => {
        const newContent = [...editQuestion.content];
        newContent[index][field] = value;
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleAddContent = () => {
        setEditQuestion({
            ...editQuestion,
            content: [...editQuestion.content, { type: 'text', value: '' }]
        });
    };

    const handleRemoveContent = (index) => {
        const newContent = [...editQuestion.content];
        newContent.splice(index, 1);
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleOptionChange = (index, field, value) => {
        const newOptions = [...editQuestion.options];
        newOptions[index][field] = value;
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleAddOption = () => {
        setEditQuestion({
            ...editQuestion,
            options: [...editQuestion.options, { type: 'text', name: '', value: '' }]
        });
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...editQuestion.options];
        newOptions.splice(index, 1);
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadClick = async (optionName, index, type) => {
        if (selectedFile) {
            try {
                const uploadResult = await uploadFileToS3(selectedFile, editQuestion.id, uploadedImageCount + 1, optionName);
                setUploadedFileUrl(uploadResult.Location);
                setUploadedImageCount(uploadedImageCount + 1);
                alert('File has been uploaded to S3.');

                // Update the corresponding content or option with the uploaded file URL
                if (type === 'content') {
                    handleContentChange(index, 'value', uploadResult.Location);
                } else if (type === 'option') {
                    handleOptionChange(index, 'value', uploadResult.Location);
                }
            } catch (error) {
                alert('Failed to upload image.');
            }
        } else {
            alert('No file selected.');
        }
    };

    const renderContent = (question) => {
        if (!Array.isArray(question?.content)) {
            return null;
        }
        return question.content.map((item, index) => {
            if (item.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: renderFormula(item.value) }} />
                    </div>
                );
            } else if (item.type === 'image') {
                return <img key={index} src={item.value} alt={`content-${index}`} style={{ maxWidth: '100%' }} />;
            } else {
                return null;
            }
        });
    };

    const renderOptions = (question) => {
        if (!Array.isArray(question?.options)) {
            return null;
        }
        return question.options.map((option, index) => {
            if (option.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: `<strong>${option.name}</strong>: ${renderFormula(option.value)}` }} />
                    </div>
                );
            } else if (option.type === 'image') {
                return (
                    <div key={index}>
                        <Typography><strong>{option.name}:</strong></Typography>
                        <img src={option.value} alt={`option-${index}`} style={{ maxWidth: '100%' }} />
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    const renderFormula = (text) => {
        const element = document.createElement('div');
        element.innerHTML = text;

        const formulas = element.querySelectorAll('.ql-formula');
        formulas.forEach(formula => {
            const formulaText = formula.getAttribute('data-value');
            const renderedFormula = katex.renderToString(formulaText, {
                throwOnError: false
            });
            formula.innerHTML = renderedFormula;
        });

        return element.innerHTML;
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader />
            <AdminMenuDrawer />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Box sx={{ width: '100%' }}>
                    <Typography variant="h4" gutterBottom>
                        AI Admin Page - Math
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3} md={2}>
                            <Select
                                fullWidth
                                value={selectedTag}
                                onChange={handleTagChange}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    태그 선택
                                </MenuItem>
                                {TAGS.map((tag, index) => (
                                    <MenuItem key={index} value={tag}>
                                        {tag}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <Select
                                fullWidth
                                value={selectedDetailedTag}
                                onChange={(e) => setSelectedDetailedTag(e.target.value)}
                                displayEmpty
                                disabled={!selectedTag}
                            >
                                <MenuItem value="" disabled>
                                    세부 태그 선택
                                </MenuItem>
                                {selectedTag && DETAILED_TAGS[selectedTag].map((detailedTag, index) => (
                                    <MenuItem key={index} value={detailedTag}>
                                        {detailedTag}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <Select
                                fullWidth
                                value={difficulty}
                                onChange={(e) => setDifficulty(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    난이도 선택
                                </MenuItem>
                                <MenuItem value="하">하</MenuItem>
                                <MenuItem value="중">중</MenuItem>
                                <MenuItem value="상">상</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <Select
                                fullWidth
                                value={questionType}
                                onChange={(e) => setQuestionType(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    질문 유형 선택
                                </MenuItem>
                                <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                                <MenuItem value="short answer">Short Answer</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeImage}
                                        onChange={(e) => setIncludeImage(e.target.checked)}
                                    />
                                }
                                label="Include Image"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <TextField
                                fullWidth
                                label="Number of Questions"
                                type="number"
                                value={numQuestions}
                                onChange={(e) => setNumQuestions(e.target.value)}
                                inputProps={{ min: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleGenerateQuestion}
                                disabled={loading}
                            >
                                질문 생성
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {loading && <CircularProgress />}
                            {questions.length > 0 && questions.map((q, index) => (
                                <Box key={index} mt={3}>
                                    <Typography variant="h6"><strong>Content:</strong></Typography>
                                    {renderContent(q)}
                                    <Typography variant="h6"><strong>Options:</strong></Typography>
                                    {renderOptions(q)}
                                    <Typography variant="h6"><strong>Answer:</strong> {q.answer}</Typography>
                                    <Typography variant="h6"><strong>Explanation:</strong></Typography>
                                    <div dangerouslySetInnerHTML={{ __html: renderFormula(q.explanation) }} className="prose" />
                                    <Typography variant="h6"><strong>Difficulty:</strong> {difficultyMapping[q.difficulty]}</Typography>
                                    <Typography variant="h6"><strong>Tag:</strong> {q.tag}</Typography>
                                    <Typography variant="h6"><strong>Detailed Tag:</strong> {q.detailed_tag}</Typography>
                                    <Typography variant="h6"><strong>Question Type:</strong> {q.questionType}</Typography>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleEditClick(q)}
                                        sx={{ mt: 2 }}
                                    >
                                        수정하기
                                    </Button>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="lg">
                <DialogTitle>문제 수정</DialogTitle>
                <DialogContent>
                    <DialogContentText>문제의 세부 사항을 수정합니다.</DialogContentText>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Difficulty</Typography>
                        <Select
                            name="difficulty"
                            value={editQuestion?.difficulty || '중'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value="상">상</MenuItem>
                            <MenuItem value="중">중</MenuItem>
                            <MenuItem value="하">하</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Question Type</Typography>
                        <Select
                            name="questionType"
                            value={editQuestion?.questionType || 'multiple choice'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                            <MenuItem value="short answer">Short Answer</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Content</Typography>
                        {editQuestion?.content.map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleContentChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {item.type === 'text' ? (
                                    <ReactQuill
                                        value={item.value}
                                        onChange={(content) => handleContentChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Value"
                                            value={item.value}
                                            onChange={(e) => handleContentChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <Button onClick={() => handleUploadClick('content', index, 'content')} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveContent(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddContent} color="primary" startIcon={<AddIcon />}>
                            Add Content
                        </Button>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Options</Typography>
                        {editQuestion?.options.map((option, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={option.type}
                                    onChange={(e) => handleOptionChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {option.type === 'text' ? (
                                    <ReactQuill
                                        value={option.value}
                                        onChange={(content) => handleOptionChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Image URL"
                                            value={option.value}
                                            onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <Button onClick={() => handleUploadClick(option.name, index, 'option')} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveOption(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddOption} color="primary" startIcon={<AddIcon />}>
                            Add Option
                        </Button>
                    </Box>
                    <TextField
                        margin="dense"
                        name="answer"
                        label="Answer"
                        value={editQuestion?.answer || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Explanation</Typography>
                        <ReactQuill
                            value={editQuestion?.explanation || ''}
                            onChange={(content) => setEditQuestion({ ...editQuestion, explanation: content })}
                            modules={{
                                toolbar: [
                                    [{ 'size': [] }, { 'font': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                    ['clean'], [{ 'formula': 'formula' }],
                                    [{ 'color': [] }, { 'background': [] }],
                                ],
                                clipboard: {
                                    matchVisual: false,
                                },
                            }}
                            formats={[
                                'size', 'font',
                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                'list', 'bullet', 'indent', 'color', 'background', 'formula'
                            ]}
                            className="prose quill-editor"
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Tag</Typography>
                        <Select
                            name="tag"
                            value={editQuestion?.tag || ''}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {TAGS.map((tag, index) => (
                                <MenuItem key={index} value={tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Detailed Tag</Typography>
                        <Select
                            name="detailed_tag"
                            value={editQuestion?.detailed_tag || ''}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {DETAILED_TAGS[editQuestion?.tag]?.map((detailedTag, index) => (
                                <MenuItem key={index} value={detailedTag}>
                                    {detailedTag}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AIAdminPage;
