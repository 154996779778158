import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, MenuItem, Select, FormControl, InputLabel, Button
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from '../../../contexts/Apicontext';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Loading/Loading';

// English tags and detailed tags
const TAGS = [
    "Information and Ideas",
    "Expression of Ideas",
    "Craft and Structure",
    "Standard English Conventions"
];

const DETAILED_TAGS = {
    "Information and Ideas": {
        "Command of Evidence": [],
        "Inference": [],
        "Central Ideas and Details": []
    },
    "Craft and Structure": {
        "Words in Context": [],
        "Text Structure and Purpose": [],
        "Cross-Text Connections": []
    },
    "Expression of Ideas": {
        "Rhetorical Synthesis": [],
        "Transitions": []
    },
    "Standard English Conventions": {
        "Boundaries": [],
        "Form, Structure, and Sense": ["SubjectVerbAgreement", "PronounCases"]
    }
};

const EnglishGeneratedQuestionsTable = () => {
    const [questions, setQuestions] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [page, setPage] = useState(() => {
        const savedPage = parseInt(new URLSearchParams(window.location.search).get('page'), 10);
        return isNaN(savedPage) ? 0 : savedPage;
    });
    const [rowsPerPage, setRowsPerPage] = useState(() => {
        const savedRowsPerPage = parseInt(new URLSearchParams(window.location.search).get('rowsPerPage'), 10);
        return isNaN(savedRowsPerPage) ? 10 : savedRowsPerPage;
    });
    const [selectedTag, setSelectedTag] = useState('');
    const [selectedDetailedTag, setSelectedDetailedTag] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const apiBaseUrl = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        fetchQuestions();
    }, [page, rowsPerPage, selectedTag, selectedDetailedTag]);

    const fetchQuestions = async () => {
        setLoading(true); // Set loading state to true when fetching starts
        const token = localStorage.getItem('token');
        const url = new URL(`${apiBaseUrl}/admin/generated-questions-english`);
        url.searchParams.append('page', page + 1);
        url.searchParams.append('limit', rowsPerPage);

        if (selectedTag) {
            url.searchParams.append('tag', selectedTag);
        }

        if (selectedDetailedTag) {
            url.searchParams.append('detailedTag', selectedDetailedTag);
        }

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            console.log('Fetched questions:', data);
            setQuestions(data.questions);
            setTotalQuestions(data.totalQuestions);
        } catch (error) {
            console.error('Error fetching questions:', error);
        } finally {
            setLoading(false); // Set loading state to false when fetch is complete
        }
    };

    const handleMoreClick = (question) => {
        navigate(`/admin/ENG_AI_LIST/${question.GeneratedQuestionID}`, { state: { rawquestion: question } });
    };

    const handleDeleteClick = async (questionID) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this question?');
        if (confirmDelete) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/generated-questions-english/${questionID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                alert('Deleted! Question has been deleted.');
                fetchQuestions();
            } else {
                alert('Error! Failed to delete question.');
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        updateUrlParams(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to first page
        updateUrlParams(0, newRowsPerPage);
    };

    const updateUrlParams = (newPage, newRowsPerPage) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', newPage);
        urlParams.set('rowsPerPage', newRowsPerPage);
        window.history.replaceState(null, '', `?${urlParams.toString()}`);
    };

    if (loading) {
        return <Loading />; // Render loading component while loading
    }

    return (
        <div className='w-full'>
            {/* Filters */}
            <div style={{ marginBottom: '20px' }}>
                <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
                    <InputLabel id="tag-select-label">Tag</InputLabel>
                    <Select
                        labelId="tag-select-label"
                        value={selectedTag}
                        onChange={(e) => {
                            setSelectedTag(e.target.value);
                            setSelectedDetailedTag('');  // Reset detailed tag when tag changes
                        }}
                        label="Tag"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {TAGS.map(tag => (
                            <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="detailed-tag-select-label">Detailed Tag</InputLabel>
                    <Select
                        labelId="detailed-tag-select-label"
                        value={selectedDetailedTag}
                        onChange={(e) => setSelectedDetailedTag(e.target.value)}
                        label="Detailed Tag"
                        disabled={!selectedTag}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {(DETAILED_TAGS[selectedTag] ? Object.keys(DETAILED_TAGS[selectedTag]) : []).map(detailedTag => (
                            <MenuItem key={detailedTag} value={detailedTag}>{detailedTag}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <TableContainer component={Paper} sx={{ width: '100%' }}>
                <Table sx={{ minWidth: 650 }} aria-label="English questions table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Difficulty</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Detailed Tags</TableCell>
                            <TableCell sx={{ position: 'sticky', right: 0 }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question) => (
                            <TableRow key={question.GeneratedQuestionID}>
                                <TableCell>{question.GeneratedQuestionID}</TableCell>
                                {/* Display a preview of the first content value using dangerouslySetInnerHTML */}
                                <TableCell>
                                    {question.content && question.content.length > 0 ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: question.content[0].value.length > 15
                                                    ? `${question.content[0].value.substring(0, 15)}...`
                                                    : question.content[0].value
                                            }}
                                        />
                                    ) : 'No content'}
                                </TableCell>
                                <TableCell>{question.difficulty}</TableCell>
                                <TableCell>{question.tags}</TableCell>
                                <TableCell>{question.detailedTags}</TableCell>
                                <TableCell sx={{ position: 'sticky', right: 0 }}>
                                    <IconButton onClick={() => handleMoreClick(question)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(question.GeneratedQuestionID)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalQuestions}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default EnglishGeneratedQuestionsTable;
