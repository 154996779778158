import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import ClassIcon from '@mui/icons-material/Class';
import QuizIcon from '@mui/icons-material/Quiz';
import LabelIcon from '@mui/icons-material/Label'; // Add icon for tags
import { useNavigate } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuBook from '@mui/icons-material/MenuBook';

const drawerWidth = 240;

const AdminMenuDrawer = ({ handlePageChange }) => {
    const navigate = useNavigate();

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <List>
                <ListItem button onClick={() => navigate('/admin/users')}>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/classes')}>
                    <ListItemIcon>
                        <ClassIcon />
                    </ListItemIcon>
                    <ListItemText primary="Classes" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/lectures')}>
                    <ListItemIcon>
                        <ClassIcon />
                    </ListItemIcon>
                    <ListItemText primary="Lectures" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/quizzes')}>
                    <ListItemIcon>
                        <QuizIcon />
                    </ListItemIcon>
                    <ListItemText primary="Quizzes" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/tags')}> {/* Add new navigation for tags */}
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tags" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/MATH_AI')}>
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <ListItemText primary="MATH_AI" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/MATH_AI_LIST')}>
                    <ListItemIcon>
                        <MenuBook />
                    </ListItemIcon>
                    <ListItemText primary="MATH_AI_LIST" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/ENG_AI')}>
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <ListItemText primary="ENG_AI" />
                </ListItem>
                <ListItem button onClick={() => navigate('/admin/ENG_AI_LIST')}>
                    <ListItemIcon>
                        <MenuBook />
                    </ListItemIcon>
                    <ListItemText primary="ENGLISH_AI_LIST" />
                </ListItem>

            </List>
        </Drawer>
    );
};

export default AdminMenuDrawer;
